import { AlertsFromProvider, ErrorCatcher, LoadingFromProvider, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { healthCheck } from "./api/UnauthenticatedClient";
import ConsentFormCompleteModal from "./components/completion/ConsentFormCompleteModal";
import Registration from "./components/registration/Registration";
import { ProspectStatusIds } from "./constants/ProspectConstants";
import { RouteConstants } from "./constants/RouteConstants";
import ConsentToDataStorage from "./containers/ConsentToDataStorage";
import Faq from "./containers/Faq";
import Home from "./containers/Home";
import LoginComponent from "./containers/Login";
import LoginCallback from "./containers/LoginCallback";
import ProspectConsentForm from "./containers/ProspectConsentForm";
import ProspectDocuments from "./containers/ProspectDocuments";
import ProspectProfile from "./containers/ProspectProfile";
import AuthenticatedContexts from "./contexts/AuthenticatedContexts";
import { useAuthentication } from "./contexts/AuthenticationContext";
import { useMetadata } from "./contexts/MetadataContext";

const ApplicationContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const AuthenticatedApp: React.FC = () => {
  const { userDetails } = useAuthentication();
  const { i18n } = useTranslation();
  const { countries } = useMetadata();
  const consentFormEnabled = userDetails?.registrationStatusId !== ProspectStatusIds.PENDING;
  const documentsEnabled = userDetails?.registrationStatusId !== ProspectStatusIds.PENDING;

  useEffect(() => {
    if (!!userDetails?.registrationCountryId && !!countries) {
      const preferredLanguageId = countries.find((c) => c.countryId === userDetails.registrationCountryId)?.languageId;
      if (preferredLanguageId === 1) {
        document.documentElement.lang = "en";
        i18n.changeLanguage("en").catch(console.error);
      } else if (preferredLanguageId === 2) {
        document.documentElement.lang = "es";
        i18n.changeLanguage("es").catch(console.error);
      }
    }
  }, [countries, userDetails?.registrationCountryId]);

  if (!userDetails) return null;

  if (!userDetails.hasConsented) {
    return <ConsentToDataStorage />;
  }

  return (
    <AuthenticatedContexts>
      <ApplicationContainer>
        <Routes>
          <Route path={RouteConstants.BASE} element={<Home />} />
          <Route path={RouteConstants.PROFILE} element={<ProspectProfile />} />
          {consentFormEnabled && (
            <>
              <Route path={RouteConstants.CONSENT_FORM} element={<ProspectConsentForm />} />
              <Route path={RouteConstants.CONSENT_FORM_SIGNED} element={<ConsentFormCompleteModal />} />
            </>
          )}
          {documentsEnabled && <Route path={RouteConstants.DOCUMENTS} element={<ProspectDocuments />} />}
          <Route path={RouteConstants.WILDCARD} element={<Navigate to={RouteConstants.BASE} replace />} />
        </Routes>
      </ApplicationContainer>
    </AuthenticatedContexts>
  );
};

const UnauthenticatedApp: React.FC = () => {
  return (
    <ApplicationContainer>
      <Routes>
        <Route path={RouteConstants.BASE} element={<LoginComponent />} />
        <Route path={RouteConstants.REGISTER} element={<Registration />} />
        <Route path={RouteConstants.LOGIN_CALLBACK} element={<LoginCallback />} />
        <Route path={RouteConstants.FAQ} element={<Faq />} />
        <Route path={RouteConstants.WILDCARD} element={<Navigate to={RouteConstants.BASE} replace />} />
      </Routes>
    </ApplicationContainer>
  );
};

const App: React.FC = () => {
  const { authenticated } = useAuthentication();
  const { setAnimated, setLabel } = useLoading();
  const { t } = useTranslation(["translation", "registration"]);
  const [healthy, setHealthy] = useState(true);

  useEffect(() => {
    healthCheck()
      .then((res) => {
        if (res.status !== 200 || res.data.status !== "UP") {
          setHealthy(false);
        }
      })
      .catch(() => setHealthy(false));
  }, []);

  useEffect(() => {
    setAnimated(true);
    setLabel(t("translation:loading"));
  }, [t]);

  if (authenticated === undefined) {
    return null;
  }

  return (
    <ErrorCatcher appName="Prospect Link" contactEmail={t("registration:email")} hasError={!healthy}>
      <AlertsFromProvider />
      <LoadingFromProvider />
      <BrowserRouter>{authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
    </ErrorCatcher>
  );
};

export default App;
