import { Button, HeroImage, Typography } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import fieldBackdrop from "../assets/field-backdrop.png";
import Footer from "../components/footer/Footer";
import UnauthenticatedNavigation from "../components/navigation/UnauthenticatedNavigation";
import { RouteConstants } from "../constants/RouteConstants";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const ContentContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 800px;
`;

const BlueText = styled.span`
  color: ${(props) => props.theme.colors["blue"]};
`;

const Faq: React.FC = () => {
  const { t, i18n } = useTranslation(["faq"]);

  const navigate = useNavigate();

  return (
    <Container>
      <UnauthenticatedNavigation />
      <HeroImage image={fieldBackdrop}>
        <Body>
          <ContentContainer>
            <Typography variant="h6">{t("faq:eligibleQuestion")}</Typography>
            <Typography>{t("faq:eligibleAnswer")}</Typography>
            <Typography variant="h6">{t("faq:startRegistrationQuestion")}</Typography>
            <Typography>
              {t("faq:startRegistrationAnswer1")}
              <Button variant="link" className="p-0" onClick={() => navigate(RouteConstants.REGISTER)}>
                {t("faq:accountURL")}.
              </Button>
              {t("faq:startRegistrationAnswer2")}{" "}
              <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                <BlueText>{t("faq:contactEmail")}</BlueText>
              </a>
              .
            </Typography>
            <Typography>{t("faq:startRegistrationAnswer3")}</Typography>
            <Typography variant="h6">{t("faq:documentationQuestion")}</Typography>
            <Typography>{t("faq:documentationAnswer1")}</Typography>
            <Typography>{t("faq:documentationAnswer2")}</Typography>
            <ul>
              <li>
                <Typography>
                  <span className="fw-bold">{t("faq:documentationAnswerBullet1Bold")}</span>{" "}
                  {t("faq:documentationAnswerBullet1")}
                </Typography>
              </li>
              <li>
                <Typography>
                  <span className="fw-bold">{t("faq:documentationAnswerBullet2Bold")}</span>{" "}
                  {t("faq:documentationAnswerBullet2")}
                </Typography>
              </li>
            </ul>
            {!!t("faq:documentationNote").length && <Typography>{t("faq:documentationNote")}</Typography>}
            <Typography>
              {t("faq:documentationAnswer3")}{" "}
              <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                <BlueText>{t("faq:contactEmail")}</BlueText>
              </a>
              {i18n.language === "es" && " "}
              {t("faq:documentationAnswer4")}
            </Typography>
            <Typography variant="h6">{t("faq:afterRegistrationQuestion")}</Typography>
            <Typography>
              {t("faq:afterRegistrationAnswer")}{" "}
              <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                <BlueText>{t("faq:contactEmail")}</BlueText>
              </a>
              .
            </Typography>
            <Typography variant="h6">{t("faq:issueRegistrationQuestion")}</Typography>
            <Typography>
              {t("faq:issueRegistrationAnswer1")}{" "}
              <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                <BlueText>{t("faq:contactEmail")}</BlueText>
              </a>{" "}
              {t("faq:issueRegistrationAnswer2")}
            </Typography>
            <Typography variant="h6">{t("faq:registrationFeeQuestion")}</Typography>
            <Typography>
              {t("faq:registrationFeeAnswer")}{" "}
              <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                <BlueText>{t("faq:contactEmail")}</BlueText>
              </a>
              .
            </Typography>
          </ContentContainer>
        </Body>
        <Footer />
      </HeroImage>
    </Container>
  );
};

export default Faq;
